import React from "react";
import { connect } from "react-redux";
import * as FlexWebChat from "@twilio/flex-webchat-ui";

class EndChatButton extends React.Component {
  constructor(props) {
    super();
    this.props = props;
  }

  render() {
    return (
      <span
        style={{ cursor: "pointer" }}
        onClick={() => {
          let state = this.props.manager.store.getState();
          // Checks channelSid and document classname checks if chat has been ended && the user is on messaging page
          if (
            state?.flex?.session?.channelSid &&
            document.getElementsByClassName("Twilio-MessagingCanvas").length &&
            !document.getElementsByClassName("Twilio-MessageCanvasTray-default").length &&
            !document.getElementById("Survey-Form") &&
            !document.getElementById("Survey-Form-Submitted")
          ) {
            return this.props.dispatch({
              type: "SET_SHOW_END_CHAT_MODAL",
              payload: { showEndChatModal: true }
            });
          }
          if (document.getElementById("Survey-Form")) {
            FlexWebChat.Actions.invokeAction("MinimizeChat");
          } else {
            FlexWebChat.Actions.invokeAction("MinimizeChat");
            FlexWebChat.Actions.invokeAction("RestartEngagement");
          }
        }}
      >
        <FlexWebChat.Icon icon="Close" />
      </span>
    );
  }
}

function mapStateToProps(state) {
  return { showEndChatModal: state.custom.showEndChatModal };
}

export default connect(mapStateToProps)(EndChatButton);
