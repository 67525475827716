// Comment out following polyfills if you don't need IE11 support
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import "regenerator-runtime/runtime";
import "./index.css";
import App from "./App/App";
import registerServiceWorker from "./registerServiceWorker";

ReactDOM.render(
  <App
    configuration={{
      ...window.appConfig,
      accountSid: process.env.REACT_APP_ACCOUNT_SID,
      flexFlowSid: process.env.REACT_APP_FLOW_SID
    }}
  />,
  document.getElementById("root")
);

registerServiceWorker();
