import React from "react";
import * as FlexWebChat from "@twilio/flex-webchat-ui";

export default class MinimizeButton extends React.Component {
  // Invokes the minimize action
  handleClick() {
    FlexWebChat.Actions.invokeAction("MinimizeChat");
  }

  render() {
    return (
      <span style={{ cursor: "pointer" }} onClick={this.handleClick.bind(this)}>
        <FlexWebChat.Icon icon="ArrowDown" />
      </span>
    );
  }
}
