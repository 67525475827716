import React from "react";
import { FormControl, FormLabel, Select, MenuItem, TextField, Checkbox, Button, CircularProgress } from "@material-ui/core";
import Greentick from "../Assets/Greentick.png";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    const { firstName, lastName, email, contact, order, taskqueue } = props?.formData;
    this.state = {
      subject: "",
      message: "",
      title: "",
      firstName: firstName || "",
      lastName: lastName || "",
      email: email || "",
      contact: contact || "",
      order: order || "",
      vehicleId: "",
      contactChannel: "",
      callbackTime: "",
      emailError: "",
      taskqueue: taskqueue || "",
      isEnquiryMail: false,
      isEnquirySent: false,
      isLoading: false
    };
  }

  // Checks if form fields are not empty
  isFieldsEmpty() {
    const { subject, message, title, firstName, lastName, email, contact, contactChannel, callbackTime, isEnquiryMail } = this.state;

    if ([subject, message, title, firstName, lastName, contactChannel].includes("")) {
      return true;
    }

    if ((contactChannel === "E-Mail" || isEnquiryMail) && email === "") {
      return true;
    }

    if (contactChannel === "Telefon" && [contact, callbackTime].includes("")) {
      return true;
    }

    return false;
  }

  // Validates fields length and format
  validateFields() {
    let valid = true;
    const { email } = this.state;
    const emailFilter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (email.length > 40) {
      this.setState({ emailError: "Bitte eine E-Mail mit weniger als 40 Zeichen eingeben." });
      valid = false;
    }
    if (!emailFilter.test(email)) {
      this.setState({ emailError: "Gültige E-Mail Adresse eingeben" });
      valid = false;
    }

    return valid;
  }

  // Sends enquiry to agent & customer
  async sendEnquiry() {
    // Enable loader
    this.setState({ isLoading: true });

    const { subject, message, title, firstName, lastName, email, contact, order, vehicleId, contactChannel, isEnquiryMail, callbackTime } = this.state;

    await fetch(`${process.env.REACT_APP_WEBCHAT_DOMAIN}/send-enquiry`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({ subject, message, title, firstName, lastName, email, contact, order, vehicleId, contactChannel, isEnquiryMail, callbackTime })
    })
      .then((resp) => resp.json())
      .then((result) => {
        console.log("/send-enquiry", result);
      })
      .catch((e) => {
        console.error("/send-enquiry", e);
      });

    this.setState({ isEnquirySent: true, isLoading: false });
  }

  render() {
    let titles = ["Herr", "Frau"].map((title) => (
      <MenuItem key={title} value={title}>
        {title}
      </MenuItem>
    ));

    titles.unshift(
      <MenuItem key="" disabled>
        Anrede
      </MenuItem>
    );

    let contactChannels = ["E-Mail", "Telefon"].map((channel) => (
      <MenuItem key={channel} value={channel}>
        {channel}
      </MenuItem>
    ));

    contactChannels.unshift(
      <MenuItem key="" disabled>
        Bevorzugte Kontaktmethode
      </MenuItem>
    );

    let callbackTime = ["Morgens", "Nachmittags", "Abends"].map((time) => (
      <MenuItem key={time} value={time}>
        {time}
      </MenuItem>
    ));

    callbackTime.unshift(
      <MenuItem key="" disabled>
        Rückrufzeit
      </MenuItem>
    );

    if (!this.state.isEnquirySent) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            overflow: "auto"
          }}
        >
          <div style={{ marginTop: "0px" }}>
            <p style={{ marginTop: "0px", fontSize: "14px", fontWeight: 700 }}>Leider erreichen Sie uns außerhalb der Öffnungszeiten</p>
            <p style={{ fontSize: "14px", fontWeight: 400 }}>
              Nennen Sie uns Ihr Anliegen über dieses Kontaktformular und unser Kunden-Support wird Ihnen schnellstmöglich helfen.
            </p>
          </div>

          <div style={{ display: "flex", direction: "row wrap" }}>
            <div className="pre-engagement-field-16" style={{ width: "94px", marginTop: "1px" }}>
              <FormControl>
                <FormLabel className="pre-engagement-label">Anrede *</FormLabel>
                <Select
                  disableUnderline
                  displayEmpty
                  name="title"
                  required={true}
                  value={this.state.title}
                  style={{ fontWeight: 600, marginTop: "0px" }}
                  onChange={(event) => {
                    this.setState({ title: event.target.value });
                  }}
                >
                  {titles}
                </Select>
              </FormControl>
            </div>

            <div className="pre-engagement-field-16" style={{ paddingLeft: "2px", marginTop: "-9px" }}>
              <FormLabel className="pre-engagement-label" style={{ position: "relative", top: "7px" }}>
                Vorname *
              </FormLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Vorname *"
                name="firstName"
                value={this.state.firstName}
                required={true}
                onChange={(event) => {
                  this.setState({ firstName: event.target.value });
                }}
              />
            </div>
          </div>

          <div className="pre-engagement-field-16">
            <FormLabel className="pre-engagement-label" style={{ position: "relative", top: "7px" }}>
              Nachname *
            </FormLabel>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Nachname *"
              name="lastName"
              required={true}
              value={this.state.lastName}
              onChange={(event) => {
                this.setState({ lastName: event.target.value });
              }}
            />
          </div>

          <div className="pre-engagement-field-16">
            <FormLabel className="pre-engagement-label" style={{ position: "relative", top: "7px" }}>
              {this.state.contactChannel === "E-Mail" || this.state.isEnquiryMail ? "Email Adresse *" : "Email Adresse"}
            </FormLabel>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder={this.state.contactChannel === "E-Mail" || this.state.isEnquiryMail ? "Email Adresse *" : "Email Adresse"}
              name="email"
              required={true}
              value={this.state.email}
              error={this.state.emailError ? true : false}
              helperText={this.state.emailError}
              onChange={(event) => {
                this.setState({ email: event.target.value, emailError: "" });
              }}
            />
          </div>

          <div className="pre-engagement-field-16">
            <FormLabel className="pre-engagement-label" style={{ position: "relative", top: "7px" }}>
              {this.state.contactChannel === "Telefon" ? "Telefonnummer *" : "Telefonnummer"}
            </FormLabel>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder={this.state.contactChannel === "Telefon" ? "Telefonnummer *" : "Telefonnummer"}
              name="contact"
              required={true}
              value={this.state.contact}
              onChange={(event) => {
                this.setState({ contact: event.target.value });
              }}
            />
          </div>

          <div className="pre-engagement-field-16">
            <FormLabel className="pre-engagement-label" style={{ position: "relative", top: "7px" }}>
              Auftragsnummer
            </FormLabel>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Auftragsnummer"
              name="order"
              value={this.state.order}
              onChange={(event) => {
                this.setState({ order: event.target.value });
              }}
            />
          </div>

          <div className="pre-engagement-field-16">
            <FormLabel className="pre-engagement-label" style={{ position: "relative", top: "7px" }}>
              Fahrzeug-Identnummer
            </FormLabel>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Fahrzeug-Identnummer"
              name="vehicle-id"
              value={this.state.vehicleId}
              onChange={(event) => {
                this.setState({ vehicleId: event.target.value });
              }}
            />
          </div>

          <div className="pre-engagement-field-16">
            <FormControl>
              <FormLabel className="pre-engagement-label">Bevorzugte Kontaktmethode *</FormLabel>
              <Select
                disableUnderline
                displayEmpty
                name="contact-channel"
                value={this.state.contactChannel}
                style={{ marginTop: "0px", fontWeight: 600 }}
                onChange={(event) => {
                  this.setState({ contactChannel: event.target.value });
                  if (event.target.value === "E-Mail") {
                    this.setState({ callbackTime: "N/A" });
                  }
                }}
              >
                {contactChannels}
              </Select>
            </FormControl>
          </div>

          {this.state.contactChannel === "Telefon" ? (
            <div className="pre-engagement-field-16">
              <FormControl>
                <FormLabel className="pre-engagement-label">Rückrufzeit *</FormLabel>
                <Select
                  disableUnderline
                  displayEmpty
                  name="callback-time"
                  value={this.state.callbackTime}
                  style={{ marginTop: "0px", fontWeight: 600 }}
                  onChange={(event) => {
                    this.setState({ callbackTime: event.target.value });
                  }}
                >
                  {callbackTime}
                </Select>
              </FormControl>
            </div>
          ) : null}

          <div className="pre-engagement-field-16">
            <FormLabel className="pre-engagement-label" style={{ position: "relative", top: "7px" }}>
              Betreff *
            </FormLabel>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Betreff *"
              name="subject"
              required={true}
              value={this.state.subject}
              onChange={(event) => {
                this.setState({ subject: event.target.value });
              }}
            />
          </div>

          <div className="pre-engagement-field-16" id="contact-message">
            <FormLabel className="pre-engagement-label" style={{ position: "relative", top: "7px" }}>
              Nachricht *
            </FormLabel>
            <TextField
              multiline
              rows={5}
              id="outlined-basic"
              variant="outlined"
              placeholder="Nachricht *"
              name="message"
              required={true}
              value={this.state.message}
              onChange={(event) => {
                this.setState({ message: event.target.value });
              }}
            />
          </div>

          <div className="pre-engagement-field-16">
            <Checkbox
              style={{ padding: "0px" }}
              checked={this.state.isEnquiryMail}
              onChange={(event) => {
                this.setState({ isEnquiryMail: event.target.checked });
              }}
              inputProps={{ "aria-label": "controlled" }}
              color="primary"
            />
            <span style={{ fontWeight: 600, fontSize: "14px", paddingLeft: "5px" }}>Kopie an mich senden</span>
          </div>

          <div className="pre-engagement-field-16">
            <Button
              className="pre-engagement-button"
              variant="contained"
              color="primary"
              disabled={this.isFieldsEmpty() || this.state.isLoading}
              onClick={async () => {
                const isValid = this.validateFields();

                if (isValid) {
                  await this.sendEnquiry();
                }
              }}
            >
              {this.state.isLoading ? <CircularProgress size={14} /> : "Anfrage senden"}
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ margin: "auto" }}>
          <img src={Greentick} width="150" height="150" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
          <h1 style={{ textAlign: "center" }}>Vielen Dank!</h1>
          <p style={{ textAlign: "center", fontSize: "initial" }}>
            Wir haben Ihre Nachricht erhalten und werden uns innerhalb von 24 Stunden bei Ihnen melden. ​
          </p>
        </div>
      );
    }
  }
}

export default ContactForm;
