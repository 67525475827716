import React from "react";
import { connect } from "react-redux";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";

const center = {
  position: "absolute",
  paddingTop: "50%",
  top: "0px",
  bottom: "0px",
  left: "0px",
  right: "0px"
};

class EndChatModal extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = {
      loading: false
    };
  }

  /* Ends the chat
  // Remove EndChatModal
  // Minimise the webchat 
  // Initiated the Pre-Engangement form */
  async handleClose(isChatOpen) {
    if (isChatOpen) {
      await this.endChat();
    }
    this.props.dispatch({
      type: "SET_SHOW_END_CHAT_MODAL",
      payload: { showEndChatModal: false }
    });
  }

  // Run the Serverless function to end the chat channel and task
  async endChat() {
    let state = this.props.manager.store.getState();
    if (state?.flex?.session?.channelSid) {
      this.setState({ loading: true });
      await fetch(`${process.env.REACT_APP_WEBCHAT_DOMAIN}/end-chat`, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({ channelSid: state.flex.session.channelSid })
      }).catch((e) => {
        console.error("/end-chat", e);
      });
      this.setState({ loading: false });
    }
  }

  render() {
    return this.props.showEndChatModal ? (
      <div id="chat-modal" style={center}>
        <Dialog
          id="chat-loader"
          open={this.state.loading}
          fullWidth={false}
          style={{ position: "absolute", background: "transparent" }}
          BackdropProps={{ style: { position: "absolute", background: "white" } }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          container={() => document.getElementById("chat-modal")}
        >
          <CircularProgress size={100} thickness={1} color="primary" />
        </Dialog>

        <Dialog
          id="dialog-main"
          open={!this.state.loading}
          onClose={() => {
            this.handleClose(false);
          }}
          style={{ position: "absolute" }}
          fullWidth={true}
          BackdropProps={{ style: { position: "absolute" } }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          container={() => document.getElementById("chat-modal")}
        >
          <DialogContent style={{ padding: "18px" }}>
            <DialogContentText className="dialog-title" id="alert-dialog-description">
              Sind Sie sicher, dass Sie den Chat beenden möchten?
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: "0px" }}>
            <Button
              className="dialog-btn"
              onClick={() => {
                this.handleClose(false);
              }}
            >
              Nein
            </Button>
            <Button
              className="dialog-btn"
              onClick={() => {
                this.handleClose(true);
              }}
              autoFocus
            >
              Ja
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return { showEndChatModal: state.custom.showEndChatModal };
};

export default connect(mapStateToProps)(EndChatModal);
