import React from "react";
import { FormLabel, Button, CircularProgress } from "@material-ui/core";
import Greentick from "../Assets/Greentick.png";
import "../../index.css";

class SurveyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSurveySubmitted: false,
      friendlinessRate: 0,
      competenceRate: 0,
      satisfactionRate: 0,
      friendlinessHover: 0,
      competenceHover: 0,
      satisfactionHover: 0
    };
  }

  // Submit survey ratings
  async submitSuvey() {
    // Enable loader
    this.setState({ isLoading: true });
    const { friendlinessRate, competenceRate, satisfactionRate } = this.state;

    await fetch(`${process.env.REACT_APP_WEBCHAT_DOMAIN}/submit-survey`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({
        friendlinessRate,
        competenceRate,
        satisfactionRate,
        channelSid: this.props.manager?.store?.getState().flex?.session?.channelSid
      })
    }).catch((e) => {
      console.error("/submit-survey", e);
    });

    this.setState({ isSurveySubmitted: true, isLoading: false });
  }

  render() {
    console.log("isSurveySubmitted >>>>>>>> ", this.state.isSurveySubmitted);
    if (!this.state.isSurveySubmitted) {
      return (
        <div
          id="Survey-Form"
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "10px",
            overflow: "auto"
          }}
        >
          <div style={{ marginTop: "0px" }}>
            <p style={{ marginTop: "0px", fontSize: "13px", fontWeight: 700 }}>Wie bewerten Sie den heutigen Service?</p>
          </div>

          <div className="survey-form-div" style={{ marginTop: "15px" }}>
            <FormLabel className="survey-form-label">Freundlichkeit</FormLabel>
            <div style={{ alignSelf: "center" }}>
              {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                  <button
                    type="button"
                    key={index}
                    className={index <= (this.state.friendlinessHover || this.state.friendlinessRate) ? "button-on" : "button-off"}
                    onClick={() => this.setState({ friendlinessRate: index })}
                    onMouseEnter={() => this.setState({ friendlinessHover: index })}
                    onMouseLeave={() => this.setState({ friendlinessHover: this.state.friendlinessRate })}
                  >
                    <span className="star">&#9733;</span>
                  </button>
                );
              })}
            </div>
          </div>

          <div className="survey-form-div">
            <FormLabel className="survey-form-label">Kompetenz</FormLabel>
            <div style={{ alignSelf: "center" }}>
              {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                  <button
                    type="button"
                    key={index}
                    className={index <= (this.state.competenceHover || this.state.competenceRate) ? "button-on" : "button-off"}
                    onClick={() => this.setState({ competenceRate: index })}
                    onMouseEnter={() => this.setState({ competenceHover: index })}
                    onMouseLeave={() => this.setState({ competenceHover: this.state.competenceRate })}
                  >
                    <span className="star">&#9733;</span>
                  </button>
                );
              })}
            </div>
          </div>

          <div className="survey-form-div">
            <FormLabel className="survey-form-label">Konnte Ihr Anliegen gelöst werden?</FormLabel>
            <div style={{ alignSelf: "center" }}>
              {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                  <button
                    type="button"
                    key={index}
                    className={index <= (this.state.satisfactionHover || this.state.satisfactionRate) ? "button-on" : "button-off"}
                    onClick={() => this.setState({ satisfactionRate: index })}
                    onMouseEnter={() => this.setState({ satisfactionHover: index })}
                    onMouseLeave={() => this.setState({ satisfactionHover: this.state.satisfactionRate })}
                  >
                    <span className="star">&#9733;</span>
                  </button>
                );
              })}
            </div>
          </div>

          <div style={{ marginTop: "45px" }}>
            <Button
              className="pre-engagement-button"
              variant="contained"
              color="primary"
              disabled={this.state.isLoading}
              onClick={async () => {
                await this.submitSuvey();
              }}
            >
              {this.state.isLoading ? <CircularProgress size={14} /> : "Absenden"}
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div id="Survey-Form-Submitted" style={{ margin: "auto" }}>
          <img src={Greentick} width="150" height="150" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
          <h1 style={{ textAlign: "center" }}>Vielen Dank!</h1>
          <p style={{ textAlign: "center", fontSize: "initial" }}>Wir haben Ihre Bewertung erhalten.</p>
        </div>
      );
    }
  }
}

export default SurveyForm;
