import React from "react";
import * as FlexWebChat from "@twilio/flex-webchat-ui";
import customState from "../store/state";
import customReducer from "../store/reducers/customReducer";
import PreEngagementForm from "./components/PreEngagementForm";
import ChatHeader from "./components/ChatHeader";
import EndChatModal from "./components/EndChatModal";
import QueuePositionView from "./components/QueuePositionView";
import SurveyForm from "./components/SurveyForm";

class App extends React.Component {
  state = {};

  // Handle iframe resize
  handleIframe = (init = false) => {
    const expanded = this.manager && this.manager.store.getState() && this.manager.store.getState().flex.session.isEntryPointExpanded;

    if (init) {
      if (expanded) {
        window.parent && window.parent.postMessage({ type: "setClass", value: "twilio-widget-side twilio-widget" }, "*");
      } else {
        window.parent && window.parent.postMessage({ type: "setClass", value: "twilio-widget" }, "*");
      }
    } else {
      if (!expanded) {
        window.parent && window.parent.postMessage({ type: "setClass", value: "twilio-widget-side twilio-widget" }, "*");
      } else {
        window.parent && window.parent.postMessage({ type: "setClass", value: "twilio-widget" }, "*");
      }
    }
  };

  constructor(props) {
    super(props);

    const { configuration } = props;
    this.notifCounter = 0;
    this.docTitle = document.title;
    this.flashTimer = undefined;
    this.options = {};
    this.isNotificationInitialised = false;
    this.isEndChat = false;
    this.manager = undefined;
    this.idleTimer1 = undefined;
    this.idleTimer2 = undefined;

    FlexWebChat.Manager.create(configuration)
      .then((manager) => {
        this.manager = manager;

        customState.addReducer("custom", customReducer);
        manager.store.replaceReducer(customState.combinedReducers());

        // Change Manager Strings
        this.changeStringTemplates(manager);

        // Override Components
        this.overrideComponents(manager);

        // Set manager value
        this.setState({ manager });

        // Initialise PreEngagement form
        const initialisePE = this.initialisePreEngagement(manager);

        if (initialisePE) {
          // Get Notification Permission
          this.getNotificationPermission();
        }

        // Opens the chat window after a certain amount of time
        this.openChatWindow(this.manager);

        // Initialise Notification
        FlexWebChat.Actions.addListener("afterStartEngagement", (payload) => {
          if (!this.isEndChat) this.endChatListner(manager);

          if (["Retourenschein", "Gewährleistungsantrag"].includes(payload.formData.sub_reason)) {
            fetch(`${process.env.REACT_APP_WEBCHAT_DOMAIN}/send-message`, {
              headers: { "Content-Type": "application/json" },
              method: "POST",
              body: JSON.stringify({
                channelSid: manager?.store?.getState().flex?.session?.channelSid,
                subReason: payload.formData.sub_reason
              })
            }).catch((e) => {
              console.error("/send-message", e);
            });
          }

          if (Notification.permission === "granted") {
            this.initialiseNotification(this.manager);
          }
        });

        // Add listener for Minimize/Maximize Chat
        for (const evnt of ["MinimizeChat", "ToggleChatVisibility"]) {
          FlexWebChat.Actions.addListener(`before${evnt}`, () => {
            this.handleIframe();
          });
        }

        setTimeout(() => {
          this.handleIframe(true);
        }, 200);
      })
      .catch((error) => this.setState({ error }));
  }

  // Add custom PreEngagementForm
  initialisePreEngagement = (manager) => {
    return new Promise((resolve) => {
      resolve(FlexWebChat.PreEngagementCanvas.Content.replace(<PreEngagementForm key="pre-engagement" manager={manager} />));
    });
  };

  // Traslated manager strings to German
  changeStringTemplates = (manager) => {
    manager.configuration.preEngagementConfig.description = (
      <>
        <p style={{ marginTop: "0px", fontSize: "14px", fontWeight: 700 }}>Wie können wir Ihnen heute weiterhelfen?</p>
        <p style={{ fontSize: "14px", fontWeight: 400 }}>Füllen Sie die folgenden Felder aus, um direkt mit uns zu chatten.</p>
      </>
    );

    manager.strings.AttachFileInvalidSize = "{{fileName}} ist zu groß zum Anhängen. Die maximale Dateigröße beträgt {{maxFileSize}}.";
    manager.strings.AttachFileInvalidType = "{{fileName}} kann nicht angehängt werden, da der Dateityp nicht unterstützt wird";

    manager.strings.WelcomeMessage = `
            <h3>Vielen Dank für Ihre Geduld. </h3>
            <p>
              Bitte nennen Sie uns Ihr Anliegen,
              <br /> Sie werden bald mit einem Mitarbeiter verbunden.
            </p>
         `;
    manager.strings.MessageCanvasTrayButton = "Neuen Chat starten";
    manager.strings.MessageCanvasTrayContent = `
        <h6>Vielen Dank für das Gespräch!</h6>
        <p style=" margin-left: 11px; margin-right: 11px; text-align: center;">
            Wenn Sie weitere Fragen haben, kontaktieren Sie uns bitte erneut.
        </p>`;
    manager.strings.InputPlaceHolder = "Schreiben Sie Ihre Nachricht";
    manager.strings.Today = "HEUTE";
    manager.strings.Read = "Gelesen";
  };

  // Override Props & add new content
  overrideComponents = (manager) => {
    const website = window.location !== window.parent.location ? document.referrer : document.location.href.toLowerCase();
    const logoImageUrl = website.includes("kfzteile24") ? "/img/Logo_kfz24_KFZteile24.svg" : "/img/Logo_kfz24_AutoteileKcom.svg";

    FlexWebChat.EntryPoint.defaultProps.tagline = "";
    FlexWebChat.MainHeader.defaultProps.showImage = true;
    FlexWebChat.MainHeader.defaultProps.imageUrl = logoImageUrl;
    FlexWebChat.MainHeader.defaultProps.titleText = "Chat";
    FlexWebChat.MainHeader.Content.remove("close-button");
    FlexWebChat.MessagingCanvas.defaultProps.predefinedMessage = false;
    FlexWebChat.MessagingCanvas.Content.add(<EndChatModal key="end-chat-modal" manager={manager} />);
    FlexWebChat.MessageListItem.defaultProps.avatarUrl = logoImageUrl;
    FlexWebChat.MainHeader.Content.add(<ChatHeader key="custom-chat-header" manager={manager} style={{ width: "100%" }} />);
    FlexWebChat.MessageList.Content.add(<QueuePositionView key="queue-position-view" manager={manager} />, {
      sortOrder: 0,
      if: () =>
        sessionStorage.getItem(manager?.store?.getState().flex?.session?.channelSid + "_isFirstMsg") == 1 &&
        !document.getElementsByClassName("Twilio-MessageCanvasTray-default").length
    });
  };

  // Request Notification Permission
  getNotificationPermission = () => {
    // setting the inbuilt notification to disabled state
    FlexWebChat.Notifications.handlers.browser.enabled = false;

    console.log("Notification.permission >>>>>>>>>>>>>>>", Notification.permission);
    // if the browser doesn't support notification
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      if (!this.isNotificationInitialised) this.initialiseNotification(this.manager);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission();
    } else if (Notification.permission === "denied") {
      // console.log("permission: ", Notification.permission);
    }
  };

  // Initialise Notification
  initialiseNotification = (manager) => {
    this.isNotificationInitialised = true;
    // if there is no chat channel then don't do anything
    if (!manager?.store?.getState().flex?.session?.tokenPayload) return true;
    console.log("tokenPayload >>>>>>>>>>>>>>>", manager?.store?.getState().flex?.session?.tokenPayload);

    let channelSid = manager?.store?.getState().flex?.session?.channelSid;
    let customerIdentity = manager?.store?.getState().flex?.session?.tokenPayload?.identity;
    this.addMessageListener(channelSid, customerIdentity, manager);

    console.log("(document.visibilityState >>>>>>>>>> ", document.visibilityState);
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        // The tab has become visible so clear the Notification if it is sticky.
        clearInterval(this.flashTimer);
        this.flashTimer = false;
        this.notifCounter = 0;
        document.title = this.docTitle;
      }
    });
  };

  // Add Listener when message is added
  addMessageListener = (channelSid, customerIdentity, manager) => {
    console.log("channelSid, customerIdentity >>>>>>>>>>>>", channelSid, customerIdentity);
    manager.chatClient.getChannelBySid(channelSid).then((channel) => {
      console.log("channel >>>>>>>>>>>>", channel, customerIdentity);
      channel.on("messageAdded", (message) => {
        console.log("message >>>>>>>>>>>>", message);
        if (customerIdentity !== message.author && document.visibilityState === "hidden") {
          this.notifCounter++;
          clearInterval(this.flashTimer);
          this.flashTimer = false;

          // Update title
          if (!this.flashTimer) {
            this.flashTimer = setInterval(() => {
              if (this.docTitle === document.title) {
                document.title = `(${this.notifCounter}) ${message.body || ""}`;
              } else {
                document.title = this.docTitle;
              }
            }, 500);
          }

          // Update notification badge
          this.options = {
            body: message.body || "",
            id: message.sid
          };

          if (!document.hasFocus()) {
            let notif = new Notification("New Notification", this.options);
            notif.onclick = () => {
              window.focus();
              notif.close();
            };
          }
        }

        const app = this;
        // Send system message to the user incase idle
        if (customerIdentity !== message.author && message.author !== "system" && ["visible", "hidden"].includes(document.visibilityState)) {
          app.idleTimer1 = setInterval(() => {
            clearInterval(app.idleTimer1);
            fetch(`${process.env.REACT_APP_WEBCHAT_DOMAIN}/send-message`, {
              headers: { "Content-Type": "application/json" },
              method: "POST",
              body: JSON.stringify({
                channelSid,
                message: "Sind Sie noch da? Bitte antworten Sie in den nächsten 2 Minuten, ansonsten wird der Chat automatisch beendet."
              })
            }).catch((e) => {
              console.error("/send-message", e);
            });

            app.idleTimer2 = setInterval(() => {
              clearInterval(app.idleTimer2);
              fetch(`${process.env.REACT_APP_WEBCHAT_DOMAIN}/end-chat`, {
                headers: { "Content-Type": "application/json" },
                method: "POST",
                body: JSON.stringify({ channelSid })
              }).catch((e) => {
                console.error("/end-chat", e);
              });
            }, 2 * 60 * 1000);
          }, 2 * 60 * 1000);
        }

        // Clear idle timer if the user is active
        if (customerIdentity === message.author) {
          const isFirstMsg = sessionStorage.getItem(channelSid + "_isFirstMsg");
          if (isFirstMsg === null) sessionStorage.setItem(channelSid + "_isFirstMsg", 1);
          clearInterval(app.idleTimer1);
          clearInterval(app.idleTimer2);
        }
      });

      /* Listener when the channel is closed & task goes to wrap-up mode
       * Open Survey form when task goes to wrap-up mode */
      // channel.on("updated", (payload) => {
      //   console.log("webchat: channel: update: payload >>>>>>>> ", payload);
      //   FlexWebChat.MessagingCanvas.Content.replace(<SurveyForm key="survey-form" manager={manager} />, {
      //     if: (props) => {
      //       console.log("webchat: channel: update: props >>>>>>>> ", props);
      //       if (payload.updateReasons.includes("attributes") && props.channel.source.attributes.status === "INACTIVE") {
      //         clearInterval(this.idleTimer1);
      //         clearInterval(this.idleTimer2);
      //         return true;
      //       }
      //     }
      //   });
      // });

      /* Listener when the agent accepts the task
       * Removes queuePosition component */
      channel.on("memberJoined", (payload) => {
        if (payload?.attributes?.member_type === "agent") {
          sessionStorage.setItem(channelSid + "_isFirstMsg", 2);
        }
      });
    });
  };

  endChatListner = (manager) => {
    const session = manager?.store?.getState().flex?.session;
    let channelSid = session?.channelSid;

    console.log("webchat: endChatListner: session", session);

    manager.chatClient.getChannelBySid(channelSid).then((channel) => {
      /* Listener when the channel is closed & task goes to wrap-up mode
       * Open Survey form when task goes to wrap-up mode */
      channel.on("updated", (payload) => {
        FlexWebChat.MessagingCanvas.Content.replace(<SurveyForm key="survey-form" manager={manager} />, {
          if: (props) => {
            console.log("webchat: channel: update: props >>>>>>>> ", props);
            if (payload.updateReasons.includes("attributes") && props.channel.source.attributes.status === "INACTIVE") {
              this.isEndChat = true;
              clearInterval(this.idleTimer1);
              clearInterval(this.idleTimer2);
              return true;
            }
          }
        });
      });
    });
  };

  // Pop-up chat window after 30 seconds of browsing the page
  openChatWindow = (manager) => {
    setTimeout(() => {
      const isChatWindowOpen = manager.store.getState().flex.session.isEntryPointExpanded;
      const isChatWindowOpenSession = window.sessionStorage.getItem("isChatWindowOpen");
      if (!isChatWindowOpen && isChatWindowOpenSession !== "true") {
        FlexWebChat.Actions.invokeAction("ToggleChatVisibility");
        window.sessionStorage.setItem("isChatWindowOpen", true);
      }
    }, 30 * 1000);
  };

  render() {
    const { manager, error } = this.state;

    window.manager = manager;
    if (manager) {
      return (
        <FlexWebChat.ContextProvider manager={manager}>
          <FlexWebChat.RootContainer />
        </FlexWebChat.ContextProvider>
      );
    }

    if (error) {
      console.error("Failed to initialize Flex Web Chat", error);
    }

    return null;
  }
}

export default App;
