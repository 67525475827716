import React from "react";
import * as FlexWebChat from "@twilio/flex-webchat-ui";
import ContactForm from "./ContactForm";
import {
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from "@material-ui/core";

class PreEngagementFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      contact: "",
      reason: "",
      sub_reason: "",
      order: "",
      emailError: "",
      taskqueue: "",
      isSubReason: false,
      isChatTranscript: false,
      formReasons: [],
      gdprPolicy: "",
      isOpeningHours: true,
      isLoading: false,
      isOrderNumber: false,
      isOrderError: false,
      isGdprAccepted: false
    };
  }

  componentDidMount() {
    const website = window.location !== window.parent.location ? document.referrer : document.location.href.toLowerCase();
    const gdprPolicy = website.includes("kfzteile") ? "https://www.kfzteile.com/cms/datenschutz" : "https://www.autoteile24.de/cms/datenschutz";
    this.setState({ gdprPolicy });
    this.fetchResons();
  }

  // Fetch Reasons and Sub-Reason
  fetchResons() {
    fetch(`${process.env.REACT_APP_WEBCHAT_DOMAIN}/pre-engagement-reasons`)
      .then((resp) => resp.json())
      .then((resp) => {
        this.setState({ formReasons: resp });
      })
      .catch((e) => {
        console.error("/pre-engagement-reasons", e);
      });
  }

  // Fetch opening hours using taskqueue
  async fetchOpeningHours(taskqueue) {
    // Enable loader
    this.setState({ isLoading: true });

    return await fetch(`${process.env.REACT_APP_WEBCHAT_DOMAIN}/opening-hours`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({ taskqueue })
    })
      .then((resp) => resp.json())
      .then((result) => {
        this.setState({ isOpeningHours: result.isOpen });
        return result.isOpen;
      })
      .catch((e) => {
        console.error("/opening-hours", e);
        return true;
      });
  }

  // Updates reason value and reset sub_reason
  saveContactReason(event) {
    const reason = event.target.value;
    let isSubReason = false;

    let { sub_reason, orderNumber } = this.state.formReasons.find(({ reason }) => reason === event.target.value);
    sub_reason = sub_reason.map((obj) => Object.keys(obj)[0]).filter(Boolean);

    if (sub_reason.length) isSubReason = true;

    this.setState({ reason, isSubReason, sub_reason: "", isOrderNumber: orderNumber });
  }

  // Updates sub-reason value
  saveSubContactReason(event) {
    const sub_reason = event.target.value;
    this.setState({ sub_reason });
  }

  // Checks if required form fields are not empty
  isFieldsEmpty() {
    const { email, reason, sub_reason, order, isSubReason, isOrderNumber, isChatTranscript, isGdprAccepted } = this.state;

    if (!reason) return true;

    if (isSubReason && !sub_reason) return true;

    if (isOrderNumber && order === "") return true;

    if (isChatTranscript && !email) return true;

    if (!isGdprAccepted) return true;

    return false;
  }

  // Validates fields length and format
  validateFields(source) {
    let valid = true;
    const { email, order, isOrderNumber, isChatTranscript } = this.state;

    const emailFilter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (source === "start-chat" && isOrderNumber && order.length !== 9) {
      this.setState({ isOrderError: true });
      valid = false;
    }

    if (isChatTranscript && email.length > 40) {
      this.setState({ emailError: "Bitte eine E-Mail mit weniger als 40 Zeichen eingeben" });
      valid = false;
    }
    if (isChatTranscript && !emailFilter.test(email)) {
      this.setState({ emailError: "Gültige E-Mail Adresse eingeben" });
      valid = false;
    }

    return valid;
  }

  // Fetch taskqueue using reason and sub-reason
  fetchTaskqueue() {
    const { sub_reason } = this.state.formReasons.find(({ reason }) => reason === this.state.reason) || {};
    const taskqueue = sub_reason ? Object.values(sub_reason.find((element) => element[this.state.sub_reason || ""]))[0] : "";

    this.setState({ taskqueue });

    return taskqueue;
  }

  async startEngagement(source) {
    let taskqueue;
    let isOpen = false;

    // Validate fields
    const isValid = this.validateFields(source);

    // Fetch taskqueue & check opening hours
    if ((isValid && !this.state.isOrderError) || (isValid && source === "dialog-btn")) {
      taskqueue = this.fetchTaskqueue();
      isOpen = await this.fetchOpeningHours(taskqueue);
    }

    if (isOpen) {
      let { firstName, lastName, email, contact, reason, sub_reason, order, isChatTranscript } = this.state;
      if (!firstName && !lastName) firstName = "Anonym";
      if (!contact) contact = "-";

      FlexWebChat.Actions.invokeAction("StartEngagement", {
        formData: { firstName, friendlyName: firstName + " " + lastName, lastName, email, contact, reason, sub_reason, order, taskqueue, isChatTranscript }
      });
    }
  }

  render() {
    let getContactReason = [
      <MenuItem key="" disabled>
        Anliegen
      </MenuItem>
    ];

    let getContactSubReason = [
      <MenuItem key="" disabled>
        Unterkategorie
      </MenuItem>
    ];

    const contactReasonArr = this.state.formReasons.map((res) => {
      return (
        <MenuItem key={res.reason} value={res.reason}>
          {res.reason}
        </MenuItem>
      );
    });

    getContactReason.push(...contactReasonArr);

    if (this.state.reason) {
      // Rendering Topic dropdown mapped with scenario and customer type
      let { sub_reason } = this.state.formReasons.find(({ reason }) => reason === this.state.reason);
      sub_reason = sub_reason.map((obj) => Object.keys(obj)[0]).filter(Boolean);

      if (sub_reason.length) {
        const contactSubReasonArr = sub_reason.map((res) => {
          return (
            <MenuItem key={res} value={res}>
              {res}
            </MenuItem>
          );
        });

        getContactSubReason.push(...contactSubReasonArr);
      }
    }

    if (this.state.isOpeningHours) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            overflow: "auto"
          }}
        >
          <div style={{ marginTop: "0px" }}>
            <p style={{ marginTop: "0px", fontSize: "14px", fontWeight: 700 }}>Wie können wir Ihnen heute weiterhelfen?</p>
            <p style={{ fontSize: "14px", fontWeight: 400 }}>Damit wir Ihr Anliegen schnell bearbeiten können, füllen Sie bitte folgende Felder aus.</p>
          </div>

          <div className="pre-engagement-field-16" style={{ marginTop: "1px" }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Vorname"
              name="firstName"
              value={this.state.firstName}
              required={false}
              onChange={(event) => {
                this.setState({ firstName: event.target.value });
              }}
            />
          </div>

          <div className="pre-engagement-field-16">
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Nachname"
              name="lastName"
              required={false}
              value={this.state.lastName}
              // error={this.state.sf_record_id_len_error}
              onChange={(event) => {
                this.setState({ lastName: event.target.value });
              }}
            />
          </div>

          <div className="pre-engagement-field-16">
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder={this.state.isChatTranscript ? "Email Adresse *" : "Email Adresse"}
              name="email"
              required={this.state.isChatTranscript}
              value={this.state.email}
              error={this.state.emailError ? true : false}
              helperText={this.state.emailError}
              onChange={(event) => {
                this.setState({ email: event.target.value, emailError: "" });
              }}
            />
          </div>

          <div className="pre-engagement-field-16">
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Rufnummer"
              name="contact"
              required={false}
              value={this.state.contact}
              onChange={(event) => {
                this.setState({ contact: event.target.value });
              }}
            />
          </div>

          <div className="pre-engagement-field-16">
            <FormControl>
              <FormLabel className="pre-engagement-label">Anliegen *</FormLabel>
              <Select
                disableUnderline
                displayEmpty
                name="reason"
                required={true}
                value={this.state.reason}
                style={{ fontWeight: 600, marginTop: "0px" }}
                onChange={this.saveContactReason.bind(this)}
              >
                {getContactReason}
              </Select>
            </FormControl>
          </div>

          {this.state.isSubReason ? (
            <div className="pre-engagement-field-16">
              <FormControl>
                <FormLabel className="pre-engagement-label">Unterkategorie *</FormLabel>
                <Select
                  disableUnderline
                  displayEmpty
                  disabled={!this.state.isSubReason}
                  name="subReason"
                  value={this.state.sub_reason}
                  style={{ marginTop: "0px", fontWeight: 600 }}
                  onChange={this.saveSubContactReason.bind(this)}
                >
                  {getContactSubReason}
                </Select>
              </FormControl>
            </div>
          ) : (
            false
          )}

          <div className="pre-engagement-field-16">
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder={this.state.isOrderNumber ? "Bestellnummer *" : "Bestellnummer"}
              name="order"
              required={true}
              error={this.state.isOrderError ? true : false}
              value={this.state.order}
              onChange={(event) => {
                this.setState({ order: event.target.value });
              }}
            />
          </div>

          <div className="pre-engagement-field-16">
            <Checkbox
              style={{ padding: "0px" }}
              checked={this.state.isChatTranscript}
              onChange={(event) => {
                this.setState({ isChatTranscript: event.target.checked });
              }}
              inputProps={{ "aria-label": "controlled" }}
              color="primary"
            />
            <span style={{ fontWeight: 600, fontSize: "14px", paddingLeft: "5px" }}>Ich möchte eine Kopie vom Chat per Mail</span>
          </div>

          <div className="pre-engagement-field-16">
            <Checkbox
              style={{ padding: "0px" }}
              checked={this.state.isGdprAccepted}
              onChange={(event) => {
                this.setState({ isGdprAccepted: event.target.checked });
              }}
              inputProps={{ "aria-label": "controlled" }}
              color="primary"
            />
            <span style={{ fontWeight: 600, fontSize: "14px", paddingLeft: "5px" }}>
              Ich habe die{" "}
              <a href={this.state.gdprPolicy} target="_blank" style={{ color: "inherit" }}>
                Datenschutzerklärung
              </a>{" "}
              gelesen und stimme dieser zu.*
            </span>
          </div>

          <div className="pre-engagement-field-16">
            <Button
              className="pre-engagement-button"
              variant="contained"
              color="primary"
              disabled={this.isFieldsEmpty() || this.state.isLoading}
              onClick={async () => {
                await this.startEngagement("start-chat");
              }}
            >
              {this.state.isLoading ? <CircularProgress size={14} /> : "Start Chat"}
            </Button>
          </div>

          <Dialog
            id="dialog-main"
            open={this.state.isOrderError}
            onClose={() => {
              this.setState({ isOrderError: false });
            }}
            style={{ position: "absolute" }}
            fullWidth={true}
            BackdropProps={{ style: { position: "absolute" } }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            container={() => document.getElementsByClassName("Twilio-PreEngagementCanvas")[0]}
          >
            <DialogContent style={{ padding: "18px" }}>
              <DialogContentText className="dialog-title" id="alert-dialog-description">
                Die Bestellnummer ist leider inkorrekt. Wollen Sie trotzdem fortfahren?
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ margin: "0px" }}>
              <Button
                className="dialog-btn"
                onClick={() => {
                  this.setState({ isOrderError: false });
                }}
              >
                Nein
              </Button>
              <Button
                className="dialog-btn"
                autoFocus
                onClick={async () => {
                  this.setState({ isOrderError: false });
                  await this.startEngagement("dialog-btn");
                }}
              >
                Ja
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    } else {
      return <ContactForm key="contact-form" formData={this.state} webchatDomain={process.env.REACT_APP_WEBCHAT_DOMAIN} />;
    }
  }
}

export default PreEngagementFields;
