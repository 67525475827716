import React from "react";
import MinimizeButton from "./MinimizeButton";
import EndButton from "./EndButton";

// Rendering Close and minimize button together
export default class ChatHeader extends React.Component {
  render() {
    return (
      <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", paddingRight: "5px" }} id="container">
        <EndButton key="end-chat-button" manager={this.props.manager} />
        <MinimizeButton key="minimize-chat" />
      </div>
    );
  }
}
