import React from "react";

// Rendering Close and minimize button together
export default class QueuePositionView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queuePosition: null,
      channelSid: props.manager?.store?.getState().flex?.session?.channelSid
    };
  }

  componentDidMount() {
    this.getQueuePosition();
    this.timerID = setInterval(() => {
      this.getQueuePosition();
    }, 3500);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  // Get queue position
  getQueuePosition = () => {
    fetch(`${process.env.REACT_APP_WEBCHAT_DOMAIN}/queue-position`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({
        taskQueue: this.props.manager?.chatClient?.channels?.channels?.entries().next().value[1]?.attributes?.pre_engagement_data?.taskqueue
      })
    })
      .then((resp) => resp.json())
      .then((result) => {
        // Save queue position in session storage
        if (this.state.queuePosition == null || result.queuePosition < window.sessionStorage.getItem(`${this.state.channelSid}_queuePosition`)) {
          // Stores initial queue position in session storage, incase empty
          const initialQueuePosition = window.sessionStorage.getItem(`${this.state.channelSid}_initialQueuePosition`);
          if (initialQueuePosition == null) window.sessionStorage.setItem(`${this.state.channelSid}_initialQueuePosition`, result.queuePosition);

          // Save queue position in session storage
          this.setState({ queuePosition: result.queuePosition });
          window.sessionStorage.setItem(`${this.state.channelSid}_queuePosition`, result.queuePosition);
        }
      })
      .catch((e) => {
        console.error("/queue-position", e);
      });
  };

  // Render queue position
  render() {
    const initialQueuePosition = window.sessionStorage.getItem(`${this.state.channelSid}_initialQueuePosition`);
    let queuePosition = this.state.queuePosition || window.sessionStorage.getItem(`${this.state.channelSid}_queuePosition`) || 0;
    queuePosition = queuePosition < 2 ? 1 : queuePosition - 1;

    return initialQueuePosition > 1 ? <p style={{ width: "100%", textAlign: "center" }}>In der Warteschlange mit {queuePosition} weiteren Kunden.</p> : null;
  }
}
